
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { ISR_REVALIDATE_TIME } from 'lib/constants';
import { GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import React from 'react';
import EmptyContent from '../../public/images/empty-content.svg';
import { ProdClient } from '../client/client';
import { ErrorPage as ErrorPageModel } from '../models';
interface ErrorPageProps {
    errorPageData: ErrorPageModel;
}
export default function ErrorPage({ errorPageData }: ErrorPageProps) {
    const { push } = useRouter();
    // It is necessary to have the error page 500 details in the event of Kontent.ai not working properly, while the website host is working. Change according to what 500 error page should be showing by Kontent.ai.
    const pageTitle = 500;
    const title = 'There seems to be a problem on our end.';
    const description = "<p>Don't worry, it's not you, it's us.</p> <p>Sorry about that.</p>";
    return (<Box h='auto' w='100%'>
      <Flex mt='100px' w='100%' justifyContent={{ base: 'center' }} flexWrap={{ base: 'wrap', lg: 'nowrap' }} px={{ base: 'xs', lg: 'l' }} pb='lr'>
        <Box pr={{ lg: 'l' }}>
          <Text textStyle='desktop.h2' fontSize='120px' color='cyan.ramboll' pb='xs'>
            {errorPageData?.elements.pageTitle.value ?? pageTitle}
          </Text>
          <Text textStyle='desktop.h5' fontWeight={700} pb='s'>
            {errorPageData?.elements.title.value ?? title}
          </Text>
          <Box textStyle='desktop.body.s' dangerouslySetInnerHTML={{ __html: errorPageData?.elements.description.value ?? description }}/>
          <Button variant='primary' size='medium' mt='m' mb='s' onClick={() => push('/')}>
            Ramboll home
          </Button>
        </Box>
        <Box mb='100px'>
          <EmptyContent />
        </Box>
      </Flex>
    </Box>);
}
const getStaticProps: GetStaticProps = async ({ locale }) => {
    const languageCodename = locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;
    const errorPageData = await ProdClient.item<ErrorPageModel>('n404_error_page')
        .languageParameter(languageCodename)
        .toPromise()
        .then((response) => response.data.item)
        .catch(() => null);
    return {
        props: { errorPageData } as ErrorPageProps,
        revalidate: ISR_REVALIDATE_TIME,
    };
};

    async function __Next_Translate__getStaticProps__194addf159a__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194addf159a__ as getStaticProps }
  